import React from 'react'
import _ from 'lodash'
import { Row, Col, Spin, Divider, Modal, Table, Tooltip, notification } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import { LocalStore } from '../../utils/LocalStore'
import { formatPriceByUnit, moneyCeil, moneyFormat } from '../../utils/stringUtils'
import { withNamespaces } from 'react-i18next'
import DetailFee from '../ConfigGroupDetail/DetailFee'

import moment from 'moment'
import Voucher from '../OrderDetail/Voucher'
import UserService from '../../services/user.service'

class TabFees extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // windowWidth: window.innerWidth,
        }
    }

    componentWillMount() {
        // window.addEventListener('resize', this.windowResize);
    }

    componentWillUnmount() {
        this.isUnmounted = true
        // window.removeEventListener('resize', this.windowResize);
    }

    componentDidMount() {
        this.getCoupon()
        // window.addEventListener('resize', this.windowResize);
    }

    // /**
    //  * khi window resize
    //  */
    // windowResize = () => {
    //     const windowWidth = window.innerWidth;
    //     if (!this.isUnmounted) this.setState({
    //         windowWidth: windowWidth,
    //     })
    // };

    getCoupon = () => {
        const { isLoading } = this.state
        const { orderDetail = {}, t } = this.props
        this.setState({ validating: true }, () => {
            if (isLoading) return
            UserService.getCouponOrder(orderDetail.code, true, (err, res, headers) => {
                if (!err) {
                    this.setState({
                        isLoading: false,
                        listCoupon: res,
                    })
                } else {
                    notification.error({ message: t(err.message) })
                    // this.setState({
                    //     isLoading: false,
                    //     validMess: t('message.coupon_not_valid')
                    // });
                }
            })
        })
    }

    renderListCoupon = () => {
        const { listCoupon = [] } = this.state
        return (
            <div className="txt-color-black">
                {listCoupon.map((x, y) => (
                    <p key={y}>
                        {x.code} - {x.description}
                    </p>
                ))}
            </div>
        )
    }

    renderModal = () => {
        const { orderDetail = {}, t } = this.props
        const { currentFee } = this.state
        return (
            <Modal
                title={t('config_group.feeTable')}
                visible={this.state.visibleModal}
                onOk={() => this.setState({ visibleModal: false }, () => this.setState({ currentFee: null }))}
                onCancel={() => this.setState({ visibleModal: false }, () => this.setState({ currentFee: null }))}
                width={currentFee ? 1100 : 800}
                okText={t('button.close')}
                cancelButtonProps={{ className: 'dpl-none' }}
            >
                {currentFee && (
                    <DetailFee
                        isShipment={true}
                        configGroupId={orderDetail.configGroupId}
                        feeObj={currentFee}
                        orderDetail={orderDetail}
                    />
                )}
            </Modal>
        )
    }

    renderTooltip = (reason, customerDiscountLevel) => {
        const { t } = this.props
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}

        return (
            <ul className="list-disc pl-4 mb-0">
                {reason && <li>{reason}</li>}
                {customerDiscountLevel && (
                    <li>
                        {currentLoggedUser?.customerLevel?.name} -{' '}
                        {t('fee_tab.discountCustomer', {
                            value:
                                customerDiscountLevel?.discountType === 'PERCENT'
                                    ? `${customerDiscountLevel?.discountValue}%`
                                    : formatPriceByUnit(customerDiscountLevel?.discountValue),
                        })}
                    </li>
                )}
            </ul>
        )
    }

    renderListItem = (item, index) => {
        const { t, shipmentFees = [], orderDetail } = this.props
        let currentFee = item.type
            ? shipmentFees.find(
                  x => x.code === item.type.code && x.feeMetadata && x.feeMetadata.template && x.feeMetadata.template !== 'custom'
              )
            : null
        const customerDiscountLevel = orderDetail?.customerDiscountLevels?.find(i => i?.feeCode === item?.type?.code)
        return (
            <li
                className={`_listItem dpl-flex justify-content-between align-items-center pdt5 pdbt5`}
                key={index}
            >
                <span className={'_service-name'}>
                    {item.type && item.type.name ? item.type.name : '---'}{' '}
                    {item.type && !item.type.minFee && !item.type.maxFee ? null : (
                        <Tooltip
                            title={
                                <div>
                                    <div>
                                        {t('fee_tab.min_fee')}: {item.type && formatPriceByUnit(item.type.minFee)}
                                    </div>
                                    <div>
                                        {t('fee_tab.max_fee')}: {item.type && formatPriceByUnit(item.type.maxFee)}
                                    </div>
                                </div>
                            }
                        >
                            <AntdIcon.InfoCircleOutlined
                                className="txt-size-h8 mgl5"
                                type="info-circle"
                            />
                        </Tooltip>
                    )}
                    {currentFee && (
                        <AntdIcon.DollarOutlined
                            onClick={() => this.setState({ visibleModal: true, currentFee: currentFee })}
                            className="txt-size-h8 mgl5"
                            type="dollar"
                        />
                    )}
                </span>
                {item.free ? (
                    <span className={'_service-value'}>
                        <span className="txt-color-gray pdr5 txt-through">
                            {moneyFormat(formatPriceByUnit(item.actualAmount))}
                        </span>{' '}
                        {t('shipment.tab_fees_free')}{' '}
                        {item.reason || (customerDiscountLevel && item?.actualAmount) ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                ) : item.manual && item.provisionalAmount !== null ? (
                    <span className={'_service-value'}>
                        <span className="txt-color-gray pdr5 txt-through">
                            {moneyFormat(formatPriceByUnit(item.provisionalAmount))}
                        </span>{' '}
                        {moneyFormat(formatPriceByUnit(item.actualAmount))}{' '}
                        {item.reason || customerDiscountLevel ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                ) : (
                    <span className={'_service-value'}>
                        {moneyFormat(formatPriceByUnit(item.actualAmount))}{' '}
                        {item.reason || (customerDiscountLevel && item?.actualAmount) ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                )}
            </li>
        )
    }

    renderListItemAdditionalFees = (item, index) => {
        const { t, shipmentFees = [], orderDetail } = this.props
        let currentFee = item.type
            ? shipmentFees.find(
                  x => x.code === item.type.code && x.feeMetadata && x.feeMetadata.template && x.feeMetadata.template !== 'custom'
              )
            : null
        const customerDiscountLevel =
            orderDetail?.customerDiscountLevels?.find(i => i?.feeCode === item?.type?.code) && item?.actualAmount
        return (
            <li
                className={`_listItem dpl-flex justify-content-between align-items-center pdt5 pdbt5`}
                key={index}
            >
                <span className={'_service-name'}>
                    {item.type && item.type.name ? item.type.name : '---'}{' '}
                    {item.type && !item.type.minFee && !item.type.maxFee ? null : (
                        <Tooltip
                            title={
                                <div>
                                    <div>
                                        {t('fee_tab.min_fee')}: {item.type && formatPriceByUnit(item.type.minFee)}
                                    </div>
                                    <div>
                                        {t('fee_tab.max_fee')}: {item.type && formatPriceByUnit(item.type.maxFee)}
                                    </div>
                                </div>
                            }
                        >
                            <AntdIcon.InfoCircleOutlined
                                className="txt-size-h8 mgl5"
                                type="info-circle"
                            />
                        </Tooltip>
                    )}
                    {currentFee && (
                        <AntdIcon.DollarOutlined
                            onClick={() => this.setState({ visibleModal: true, currentFee: currentFee })}
                            className="txt-size-h8 mgl5"
                            type="dollar"
                        />
                    )}
                </span>
                {item.free ? (
                    <span className={'_service-value'}>
                        <span className="txt-color-gray pdr5 txt-through">{formatPriceByUnit(item.actualAmount)}</span>{' '}
                        {t('shipment.tab_fees_free')}{' '}
                        {item?.reason || (customerDiscountLevel && item?.actualAmount) ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                ) : (
                    <span className={'_service-value'}>
                        {formatPriceByUnit(item.actualAmount)}{' '}
                        {item?.reason || (customerDiscountLevel && item?.actualAmount) ? (
                            <Tooltip title={this.renderTooltip(item?.reason, customerDiscountLevel)}>
                                <AntdIcon.QuestionCircleOutlined
                                    className="txt-size-h8 mgl10"
                                    type="question-circle"
                                />
                            </Tooltip>
                        ) : (
                            <span style={{ width: 22, display: 'inline-block' }} />
                        )}
                    </span>
                )}
            </li>
        )
    }

    renderOrderFeesItem = () => {
        const { orderDetail, listStatuses = [], t } = this.props
        const { listCoupon } = this.state
        let orderStatus = {}
        if (orderDetail) {
            orderStatus = listStatuses.find(x => x.code === orderDetail.status) || {}
        }

        // let totalValue = (orderDetail.totalValue || 0) * (orderDetail.exchangeRate || 1);

        // let totalAmount = totalValue + (orderDetail.merchantShippingCost || 0) * (orderDetail.exchangeRate || 1) + (orderDetail.totalFee || 0);

        return (
            <ul className="_orderFees bg-color-blue pdl15 pdr15 pdt10 pdbt10 border-radius4">
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalValue-name'}>{t('shipment.declareValue')}</span>
                    <span className={'_totalValue-value'}>{moneyFormat(moneyCeil(orderDetail.declareValue))}</span>
                </li>
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalFee-name'}>{t('shipment.totalFee')}</span>
                    <span className={'_totalFee-value'}>{moneyFormat(moneyCeil(orderDetail.totalFee))}</span>
                </li>
                {listCoupon?.length > 0 && (
                    <li className="_ordertotalCoupon dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                        <span className={'_totalFee-name'}>
                            {t('button.coupon')}{' '}
                            <Tooltip
                                overlayClassName={'white-tool-tip'}
                                placement="right"
                                title={this.renderListCoupon()}
                            >
                                <AntdIcon.InfoCircleOutlined
                                    className="txt-size-h8 mgl0"
                                    type="info-circle"
                                />
                            </Tooltip>
                        </span>
                        <span className={'_totalFee-value'}>-{moneyFormat(moneyCeil(orderDetail?.totalCoupon))}</span>
                    </li>
                )}
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalPaid-name'}>{t('shipment.totalPaid')}</span>
                    <span className={'_totalPaid-value'}>{moneyFormat(moneyCeil(orderDetail.totalPaid))}</span>
                </li>
                <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                    <span className={'_totalRefund-name'}>{t('shipment.totalRefund')}</span>
                    <span className={'_totalRefund-value'}>{moneyFormat(moneyCeil(orderDetail.totalRefund))}</span>
                </li>
                {!orderStatus.negativeEnd && (
                    <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white">
                        <span className={'_needRefund-name'}>
                            {orderDetail.totalUnpaid >= 0 ? t('order.need_payment') : t('order.excess_cash')}
                        </span>
                        <span className={'_needRefund-value'}>{moneyFormat(moneyCeil(orderDetail.totalUnpaid), null, true)}</span>
                    </li>
                )}
                {orderDetail.totalClaim && (
                    <React.Fragment>
                        <Divider className="mgbt10 mgt10" />
                        <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white align-items-start">
                            <span className={'_totalClaim-name'}>{t('fee_tab.claimed_refund')}</span>
                            <span className="_totalClaim-value txt-right">
                                {moneyFormat(moneyCeil(orderDetail.totalClaim))} <br />{' '}
                                <span
                                    onClick={() => this.setState({ modalVisible: true })}
                                    className="txt-size-h8 txt-color-white cursor-pointer"
                                >
                                    {t('fee_tab.detail')}
                                </span>
                            </span>
                        </li>
                    </React.Fragment>
                )}
                {orderDetail.totalCollect && (
                    <React.Fragment>
                        <Divider className="mgbt10 mgt10" />
                        <li className="_orderFeesItem dpl-flex justify-content-between align-items-center pdt5 pdbt5 txt-color-white align-items-start">
                            <span className={'_totalClaim-name'}>{t('fee_tab.collect_refund')}</span>
                            <span className="_totalCollect-value txt-right">
                                {moneyFormat(moneyCeil(orderDetail.totalCollect), null, true)} <br />{' '}
                                <span
                                    onClick={() => this.setState({ modalCollectVisible: true })}
                                    className="txt-size-h8 txt-color-white cursor-pointer"
                                >
                                    {t('fee_tab.detail')}
                                </span>
                            </span>
                        </li>
                    </React.Fragment>
                )}
            </ul>
        )
    }

    renderModalTotalClaim = () => {
        const { listFinancialClaim = [], t } = this.props
        const columns = [
            {
                title: t('fee_tab.refunded_name'),
                dataIndex: 'reason',
                className: '_totalClaim-reason break-word min-width-150',
            },
            {
                title: t('fee_tab.createdAt'),
                dataIndex: 'createdAt',
                className: '_totalClaim-reason break-word whitespace',
                render: txt => moment(txt).format('HH:mm DD/MM/YYYY'),
            },
            {
                title: t('fee_tab.claim_type'),
                dataIndex: 'financialAccount',
                className: '_totalClaim-reason break-word whitespace',
                render: text => (text ? text.name : '---'),
            },
            {
                title: t('fee_tab.amount'),
                dataIndex: 'amount',
                className: 'txt-right',
                render: txt => <span className="_totalClaim-amount whitespace">{moneyFormat(moneyCeil(txt))}</span>,
            },
            {
                title: t('fee_tab.reason'),
                dataIndex: 'memo',
                className: '_totalClaim-memo break-word whitespace-pre-wrap',
            },
        ]

        return (
            <Modal
                width={980}
                title={t('fee_tab.refunded_list')}
                visible={this.state.modalVisible}
                // onOk={this.handleOk}
                onCancel={() => this.setState({ modalVisible: false })}
                footer={null}
            >
                <Table
                    columns={columns}
                    dataSource={listFinancialClaim}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                    }}
                    // scroll={{ y: 240 }}
                />
            </Modal>
        )
    }

    renderModalTotalCollect = () => {
        const { listFinancialCollect = [], t } = this.props

        const data = _.groupBy(_.sortBy(listFinancialCollect, 'createdAt').reverse(), 'txid')

        const columns = [
            {
                title: t('fee_tab.createdAt'),
                dataIndex: 'createdAt',
                className: 'vertical-align-text-top',
                render: (txt, row) => {
                    const obj = {
                        children: (
                            <span className="_totalCollect-createdAt whitespace">
                                {txt ? moment(txt).format('HH:mm DD/MM/YYYY') : '---'}
                            </span>
                        ),
                        props: {
                            rowSpan:
                                _.sortBy(data[row.txid], 'createdAt')
                                    .reverse()
                                    .findIndex(x => x.id === row.id) === 0
                                    ? data[row.txid].length
                                    : 0,
                        },
                    }

                    return obj
                },
            },
            {
                title: t('fee_tab.refundType'),
                dataIndex: 'refundType',
                className: 'vertical-align-text-top',
                render: (txt, row) => {
                    return (
                        <div className="_totalCollect-refundType">
                            {row.financialAccount && row.financialAccount.name ? row.financialAccount.name : '---'}
                        </div>
                    )
                },
            },
            {
                title: t('fee_tab.amount'),
                dataIndex: 'amount',
                className: 'vertical-align-text-top txt-right',
                render: txt => {
                    return <div className="_totalCollect-amount">{moneyFormat(moneyCeil(txt))}</div>
                },
            },
            {
                title: t('fee_tab.reason'),
                dataIndex: 'memo',
                render: txt => {
                    return (
                        <div className="_totalClaim-memo break-word whitespace-pre-wrap">
                            {txt && txt.trim() ? txt.trim() : '---'}
                        </div>
                    )
                },
            },
        ]

        return (
            <Modal
                width={700}
                title={t('fee_tab.retrospectiveList')}
                visible={this.state.modalCollectVisible}
                // onOk={this.handleOk}
                onCancel={() => this.setState({ modalCollectVisible: false })}
                footer={null}
            >
                <Table
                    className={'table-refund'}
                    columns={columns}
                    dataSource={_.sortBy(listFinancialCollect, 'createdAt').reverse()}
                    bordered
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 25,
                    }}
                    // scroll={{ y: 240 }}
                />
            </Modal>
        )
    }

    render() {
        const { isLoadingFees, listFees = [], t, orderDetail, listStatuses = [] } = this.props

        let feesArray = _.sortBy(listFees, 'position').filter(x => x.type && !x.type.shipping && !x.type.additional)
        let shippingFeesArray = _.sortBy(listFees, 'position').filter(x => x.type && x.type.shipping && !x.type.additional)
        let additionalArray = _.sortBy(listFees, 'position').filter(x => x.type && !x.type.shipping && x.type.additional)
        return (
            <div className={'box-financial'}>
                <Spin
                    spinning={isLoadingFees === true}
                    indicator={
                        <AntdIcon.Loading3QuartersOutlined
                            type="loading"
                            style={{ fontSize: 24 }}
                            spin
                        />
                    }
                >
                    <Row className={'pdl15 pdr15'}>
                        <Col
                            xl={16}
                            sm={12}
                            className={'pdr15'}
                        >
                            {feesArray.length > 0 && (
                                <React.Fragment>
                                    <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">
                                        {t('fee_tab.service_fee')}
                                    </h3>
                                    <ul className="_fees bg-color-gray pdl15 pdr15 pdt10 pdbt10 border-radius4">
                                        {feesArray.map(this.renderListItem)}
                                    </ul>
                                </React.Fragment>
                            )}

                            {shippingFeesArray.length > 0 && (
                                <React.Fragment>
                                    <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">
                                        {t('fee_tab.transport_fee')}
                                    </h3>
                                    <ul className="_shippingFees bg-color-gray pdl15 pdr15 pdt10 pdbt10 border-radius4">
                                        {shippingFeesArray.map(this.renderListItem)}
                                    </ul>
                                </React.Fragment>
                            )}

                            {additionalArray.length > 0 && (
                                <React.Fragment>
                                    <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">
                                        {t('fee_tab.surcharge')}
                                    </h3>
                                    <ul className="_additionalFees bg-color-gray pdl15 pdr15 pdt10 pdbt10 border-radius4">
                                        {additionalArray.map(this.renderListItemAdditionalFees)}
                                    </ul>
                                </React.Fragment>
                            )}
                        </Col>
                        <Col
                            xl={8}
                            sm={12}
                        >
                            <h3 className="txt-size-h7 txt-color-black roboto-medium txt-uppercase mgbt5">
                                {t('fee_tab.order_finance')}
                            </h3>

                            {this.renderOrderFeesItem()}

                            <Voucher
                                orderDetail={orderDetail}
                                listStatuses={listStatuses}
                                t={t}
                                isShipment={true}
                            />
                        </Col>
                    </Row>
                </Spin>
                {this.renderModalTotalClaim()}
                {this.renderModalTotalCollect()}
                {this.renderModal()}
            </div>
        )
    }
}
export default withNamespaces()(TabFees)
