import React from 'react'
import {LocalStore} from "../../utils/LocalStore";
import Shipments from '../Shipment';
import Orders from '../Orders';
import PeerPayments from '../PeerPayments';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {};
        const tenantConfig = currentProjectInfo.tenantConfig || {};
        const orderConfig = tenantConfig.orderConfig || {};
        const shipmentConfig = tenantConfig.shipmentConfig || {};
        const generalConfig = tenantConfig.generalConfig || {};
        if (orderConfig.disable) {
            if (shipmentConfig.enable) {
                return <Shipments {...this.props}/>
            } else if (generalConfig.peerPaymentEnabled) {
                return <PeerPayments {...this.props}/>
            }
        }

        return <Orders {...this.props}/>;
    }
};
