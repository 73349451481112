/* eslint-disable array-callback-return */
import React from "react";
import {Row, Input, Table} from "antd";
import {moneyCeil, moneyFormat} from "../../utils/stringUtils";

export default class Percentage extends React.Component {
	static defaultProps = {
		isEdit: true,
	};
	constructor(props) {
		super(props);
		this.state = {
			tableData: [],
			priceList: [],
			feeObj: null,

		}
	}

	componentDidMount() {
		this.initTable(this.props.feeObj);
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.feeObj) !== JSON.stringify(this.props.feeObj)) {
			this.initTable(this.props.feeObj);
		}
	}

	initPriceTable = (data) => {
		let quantityList = [];
		let quantityKey = Object.keys(data);
		quantityKey.map((key, index) => {
			quantityList.push({
				from: key === 'newPrice' ? key : parseInt(key),
				to: index < quantityKey.length - 1 ? quantityKey[index + 1] !== 'newPrice' ? parseInt(quantityKey[index + 1]) : parseInt(key) + 1 : 0
			})
		});
		return quantityList;
	};

	initTable = (feeObj) => {
		const {orderDetail}= this.props
		const data = feeObj?.feeMetadata?.dataWithMarketPlace?.find((item)=>item.marketplace === orderDetail?.marketplace?.code)?.data
		let priceList = this.initPriceTable(data ? data : feeObj.feeMetadata.data);
		let tableData = [];
		priceList.map(key => {
			tableData.push({
				price: key,
				fee: data ? data[key.from] : feeObj.feeMetadata.data[key.from]
			});
		});
		this.setState({
			feeObj,
			tableData,
			priceList
		});
	};

	render() {
		const {isEdit, t} = this.props;
		const {tableData, priceList} = this.state;
		const columns = [
			{
				title: t('config_group.orderValue'),
				dataIndex: 'price',
				width: 80,
				key: 'price',
				render: ((text, record, index) => {
					if (index === priceList.length - 1) {
						return <Row
							className={'dpl-flex align-items-center'}
						>
							<span>{`${t('config_group.from')} ${moneyFormat(moneyCeil(record.price.from))}`}</span>
						</Row>
					} else
						return (
							<Row
								className={'dpl-flex align-items-center'}>
								<span>{`${t('config_group.from')} ${moneyFormat(moneyCeil(record.price.from))} ${t('config_group.to')} ${t('config_group.below')} ${moneyFormat(moneyCeil(record.price.to))}`}</span>
							</Row>
						);
				})
			},
			{
				title: `% ${t('config_group.serviceCharge')}`,
				dataIndex: 'fee',
				width: 80,
				key: 'fee',
				render: ((text, record) => {
					return (
						<Row className={'dpl-flex align-items-center'}>
							<Input disabled={!isEdit} value={record.fee}/>
						</Row>
					);
				})
			}
		];
		return (
			<div>
				<Table dataSource={tableData}
				       columns={columns}
				       pagination={false}
				       bordered
				       scroll={{x: 80 * priceList.length + 50}}
				       rowKey={record => record.price.from}>
				</Table>
			</div>
		);
	}
}
