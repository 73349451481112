import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import NotLoggedRoute from './components/PrivateRoute/NotLoggedRoute'
import lodash from 'lodash'
import Dashboard from './containers/Dashboard/Dashboard'
import Page404 from './containers/Page404'
import Page403 from './containers/Page403'

//Authentication
//Login
// import Login from './containers/Login';
import Login from './containers/NewLogin'
import OrderTracking from './containers/OrderTracking'
import Register from './containers/Register'
import ForgotPassword from './containers/ForgotPassword'
import ResetPassword from './containers/ResetPassword'
import ResetPin from './containers/ResetPin'
import ConfirmEmail from './containers/ConfirmEmail'
import EmailChange from './containers/ConfirmEmail/MailChange'
import BiffinRedirect from './containers/BiffinRedirect'

//Carts
import Carts from './containers/Carts'
//Order Detail
import OrderDetail from './containers/OrderDetail'

//Orders
import Orders from './containers/Orders'

//Profile
import Profile from './containers/Profile'
import TransactionHistory from './containers/Profile/TransactionHistory'
import TransactionHistoryByAccount from './containers/Profile/TransactionHistoryByAccount'
import VipLevels from './containers/Profile/VipLevels'
import ProfileAddress from './containers/Profile/Address'
import ProfileFaqs from './containers/Profile/Faqs'
import SavedProducts from './containers/Profile/SavedProducts'
import NotificationConfig from './containers/Profile/NotificationConfig'
import PurchasingAccount from './containers/Profile/PurchasingAccount'
import LinkBifiin from './containers/Profile/LinkBifiin'
import Coupon from './containers/Profile/Coupon'
import Zalo from './containers/Profile/Zalo'
import ConnectApplication from './containers/Profile/ConnectApplication'

//Delivery
import Delivery from './containers/Delivery'
import Packages from './containers/Delivery/Packages'
import DeliveryNotes from './containers/Delivery/DeliveryNotes'
import CreateDelivery from './containers/Delivery/CreateDelivery'

//Waybills
import Waybills from './containers/Waybills'
//Package Damage
import PackageDamage from './containers/PackageDamage'
//Peer Payments
import PeerPayments from './containers/PeerPayments'
import PeerPaymentDetail from './containers/PeerPayments/Detail'
//Shipment
import Shipment from './containers/Shipment'
import CreateShipment from './containers/Shipment/Create'
//Shipment detail
import ShipmentDetail from './containers/ShipmentDetail'

//Notifications detail
import Notifications from './containers/Notifications'

//Complain
import CreateTicketAdvance from './containers/CreateTicketAdvance'
import TicketDetail from './containers/TicketDetail'
import Tickets from './containers/Tickets'
import { LocalStore } from './utils/LocalStore'
import { Test } from './containers/Test'
import RequestWithDrawal from './containers/RequestWithDrawal'
import OrderTaobaoGlobal from './containers/TaobaoGlobal/Order'

// taobaoglobal
// import TaobaoGlobalList from './containers/TaobaoGlobal/List/List';
import TaobaoGlobalCart from './containers/TaobaoGlobal/Cart/TaobaoGlobalCart'
import AddressChinaList from './containers/TaobaoGlobal/AddressChinaList'
import Statistics from './containers/Statistics'
import Lieferscheine from './containers/Delivery/Lieferscheine'
import Telegram from './containers/Profile/Telegram'

export default class Routes extends React.Component<any, any> {
    render() {
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const tenantConfig = currentProjectInfo?.tenantConfig || {}
        const orderConfig = tenantConfig && tenantConfig.orderConfig ? tenantConfig.orderConfig : {}
        const shipmentConfig = tenantConfig && tenantConfig.shipmentConfig ? tenantConfig.shipmentConfig : {}
        const generalConfig = tenantConfig && tenantConfig.generalConfig ? tenantConfig.generalConfig : {}
        const zaloConfig = tenantConfig && tenantConfig.zaloConfig ? tenantConfig.zaloConfig : {}
        const telegramConfig = lodash.get(tenantConfig,'externalIntegrationConfig.telegramConfig.enabled', false)
        let suspensionSchedule = currentProjectInfo?.suspensionSchedule
        let openAPI = lodash.get(currentLoggedUser, 'customerAuthorities.openAPI', false)
        let disabled =
            suspensionSchedule &&
            Array.isArray(suspensionSchedule.appliedFor) &&
            !!suspensionSchedule.appliedFor.find((x: any) => x === 'ALL' || x === 'SHIPMENT')
        const m24Config = tenantConfig ? tenantConfig?.m24Config?.enabled : {}
        const bifinConfigEnabled = currentProjectInfo.tenantConfig
            ? currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.enabled
            : false
        const shopkeeperAuth = lodash.get(currentLoggedUser, 'customerAuthorities.shopkeeper')
        const diorConfig = currentProjectInfo?.tenantConfig?.diorConfig?.enabled
        const showHermes = lodash.get(tenantConfig, 'diorConfig.showHermes', false)

        return (
            <HashRouter>
                <div className={'main-body'}>
                    <Switch>
                        <PrivateRoute
                            exact={true}
                            path="/"
                            component={Dashboard}
                        />
                        {!orderConfig.disable && [
                            <PrivateRoute
                                key={1}
                                exact={true}
                                path="/carts"
                                component={Carts}
                            />,
                            <PrivateRoute
                                key={2}
                                exact={true}
                                path="/orders"
                                component={Orders}
                            />,
                            <PrivateRoute
                                key={3}
                                exact={true}
                                path="/orders/:id"
                                component={OrderDetail}
                            />,
                        ]}
                        <PrivateRoute
                            exact={true}
                            path="/profile"
                            component={Profile}
                        />
                        {/* <PrivateRoute exact={true} path="/taobaoglobal_list" component={TaobaoGlobalList} /> */}
                        {generalConfig.peerPaymentEnabled &&
                            m24Config && [
                                <PrivateRoute
                                    key={'taobaoglobal_cart'}
                                    exact={true}
                                    path="/taobaoglobal_cart"
                                    component={TaobaoGlobalCart}
                                />,
                                <PrivateRoute
                                    key={'global_address'}
                                    exact={true}
                                    path="/global_address"
                                    component={AddressChinaList}
                                />,
                                <PrivateRoute
                                    key={'orders-taobao-global'}
                                    exact={true}
                                    path="/orders-taobao-global"
                                    component={OrderTaobaoGlobal}
                                />,
                            ]}

                        {showHermes && (
                            <PrivateRoute
                                exact={true}
                                path="/profile/transactions"
                                component={TransactionHistory}
                            />
                        )}
                        {diorConfig && (
                            <PrivateRoute
                                exact={true}
                                path="/profile/new-transactions"
                                component={TransactionHistoryByAccount}
                            />
                        )}
                        {generalConfig && generalConfig.customerWithdrawalEnabled && (
                            <PrivateRoute
                                exact={true}
                                path="/request-withdraw-money"
                                component={RequestWithDrawal}
                            />
                        )}
                        {generalConfig.peerPaymentEnabled && (
                            <PrivateRoute
                                exact={true}
                                path="/profile/purchasing-account"
                                component={PurchasingAccount}
                            />
                        )}
                        {generalConfig.enableRewardPoint && generalConfig.enableCustomerLevel && (
                            <PrivateRoute
                                exact={true}
                                path="/profile/vip-levels"
                                component={VipLevels}
                            />
                        )}
                        <PrivateRoute
                            exact={true}
                            path="/profile/address"
                            component={ProfileAddress}
                        />
                        <PrivateRoute
                            exact={true}
                            path="/profile/faqs"
                            component={ProfileFaqs}
                        />
                        <PrivateRoute
                            exact={true}
                            path="/profile/coupon"
                            component={Coupon}
                        />
                        {bifinConfigEnabled && shopkeeperAuth && (
                            <PrivateRoute
                                exact={true}
                                path="/profile/bifiin"
                                component={LinkBifiin}
                            />
                        )}

                        {zaloConfig.zaloOaId && (
                            <PrivateRoute
                                exact={true}
                                path="/profile/zalo"
                                component={Zalo}
                            />
                        )}
                        
                        {telegramConfig &&
                            <PrivateRoute
                                exact={true}
                                path="/profile/telegram"
                                component={Telegram}
                            />
                        }

                        {!orderConfig.disable && (
                            <PrivateRoute
                                exact={true}
                                path="/profile/products"
                                component={SavedProducts}
                            />
                        )}
                        <PrivateRoute
                            exact={true}
                            path="/profile/notification"
                            component={NotificationConfig}
                        />
                        {openAPI && (
                            <Route
                                exact={true}
                                path="/profile/connect-application"
                                component={ConnectApplication}
                            />
                        )}

                        {(shipmentConfig.enable || !orderConfig.disable) && [
                            <PrivateRoute
                                key={'tickets'}
                                exact={true}
                                path="/tickets"
                                component={Tickets}
                            />,
                            <PrivateRoute
                                key={'tickets/create'}
                                exact={true}
                                path="/tickets/create"
                                component={CreateTicketAdvance}
                            />,
                            <PrivateRoute
                                key={'tickets/detail'}
                                exact={true}
                                path="/tickets/:id"
                                component={TicketDetail}
                            />,
                            <PrivateRoute
                                key={'delivery'}
                                exact={true}
                                path="/delivery"
                                component={Delivery}
                            />,
                            <PrivateRoute
                                key={'lieferscheine'}
                                exact={true}
                                path="/lieferscheine"
                                component={Lieferscheine}
                            />,
                            <PrivateRoute
                                key={'packages'}
                                exact={true}
                                path="/packages"
                                component={Packages}
                            />,
                            <PrivateRoute
                                key={'delivery/create'}
                                exact={true}
                                path="/delivery/create"
                                component={CreateDelivery}
                            />,
                        ]}

                        {(shipmentConfig.enable || !orderConfig.disable) &&
                            tenantConfig.m6Activated &&
                            generalConfig.packageDamageEnabled && (
                                <PrivateRoute
                                    exact={true}
                                    path="/package-damage"
                                    component={PackageDamage}
                                />
                            )}
                        {generalConfig.peerPaymentEnabled && (
                            <PrivateRoute
                                exact={true}
                                path="/peer-payments"
                                component={PeerPayments}
                            />
                        )}
                        {generalConfig.peerPaymentEnabled && (
                            <PrivateRoute
                                exact={true}
                                path="/peer-payments/:id"
                                component={PeerPaymentDetail}
                            />
                        )}

                        {shipmentConfig.enable && (
                            <PrivateRoute
                                exact={true}
                                path="/waybills"
                                component={Waybills}
                            />
                        )}

                        {(shipmentConfig.enable || !orderConfig.disable) && tenantConfig.m6Activated && (
                            <PrivateRoute
                                exact={true}
                                path="/delivery-notes"
                                component={DeliveryNotes}
                            />
                        )}

                        {!disabled && (
                            <PrivateRoute
                                exact={true}
                                path="/shipments/create"
                                component={CreateShipment}
                            />
                        )}
                        {shipmentConfig.enable && [
                            <PrivateRoute
                                key={'shipments'}
                                exact={true}
                                path="/shipments"
                                component={Shipment}
                            />,
                            <PrivateRoute
                                key={'shipments/detail'}
                                exact={true}
                                path="/shipments/:id"
                                component={ShipmentDetail}
                            />,
                        ]}

                        <PrivateRoute
                            exact={true}
                            path="/notifications"
                            component={Notifications}
                        />
                        <PrivateRoute
                            exact={true}
                            path="/statistics"
                            component={Statistics}
                        />
                        {/*<NotLoggedRoute exact={true} path="/authentication" component={Authentication} RouteKey={true}/>*/}
                        <NotLoggedRoute
                            exact={true}
                            path="/login"
                            component={Login}
                        />
                        <NotLoggedRoute
                            exact={true}
                            path="/register"
                            component={Register}
                        />
                        <NotLoggedRoute
                            exact={true}
                            path="/forgot-password"
                            component={ForgotPassword}
                        />
                        <NotLoggedRoute
                            exact={true}
                            path="/reset-password"
                            component={ResetPassword}
                        />
                        <NotLoggedRoute
                            exact={true}
                            path="/verification"
                            component={ConfirmEmail}
                        />
                        <Route
                            exact={true}
                            path="/reset-pin"
                            component={ResetPin}
                        />
                        <Route
                            exact={true}
                            path="/befin/authorized/callback"
                            component={BiffinRedirect}
                        />
                        <Route
                            exact={true}
                            path="/mail-changing-confirmation"
                            component={EmailChange}
                        />
                        <Route
                            exact={true}
                            path="/403"
                            component={Page403}
                        />
                        {generalConfig.publicTrackingInterface && (
                            <Route
                                exact={true}
                                path="/order-tracking"
                                component={OrderTracking}
                            />
                        )}
                        <Route
                            path="/test-phat"
                            component={Test}
                        />
                        <Route
                            path="*"
                            component={Page404}
                        />
                    </Switch>
                </div>
            </HashRouter>
        )
    }
}
