/**
 * create by Taobao dev team 19/1/2018
 */
import React, { Fragment } from 'react'

import { Row, Col, Collapse, Empty, Button, Spin, Alert, Divider, Space, Switch, Modal, Tooltip } from 'antd'
import * as AntdIcon from '@ant-design/icons'

import ListAddressModal from './ListAddressModal'
import { withNamespaces } from 'react-i18next'
import UserServices from '../../services/user.service'

const Panel = Collapse.Panel

class DeliveryAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            selectedReceiveAddress: null,
            isReceivingAddress: false,
            openReceiveAddress: false,
        }
    }

    /**
     * lấy ra danh sách trạng thái của package
     */
    checkWareHouse = id => {
        UserServices.checkWareHouse(id, (err, res, headers) => {
            if (err === null) {
                this.setState({
                    checkWareHouse: res,
                })
            }
        })
    }

    /**
     * mở modal chọn address
     */
    toggleModalAddress = () => {
        this.setState({ modalAddressStatus: !this.state.modalAddressStatus })
    }

    /**
     * mở modal tạo address
     */
    toggleModalCreateAddress = () => {
        this.setState({ modalCreateAddressStatus: !this.state.modalCreateAddressStatus })
    }

    /**
     * thay đổi address
     * @param address
     * @param cb
     */
    changeAddressSelected = (address, cb) => {
        const { updateDraftOrder, updateLoadingStatus } = this.props
        if (address) {
            this.checkWareHouse(address.id)
            updateDraftOrder({ address: address.id }, () => {
                this.setState({
                    selectedAddress: address,
                    isLoading: false,
                })
                if (cb) cb()
            })
        } else {
            updateLoadingStatus(false)
        }
    }

    changeReceiveAddressSelected = (address, cb) => {
        const { updateDraftOrder, updateLoadingStatus } = this.props
        const { selectedAddress } = this.state
        if (address) {
            this.checkWareHouse(address.id)
            updateDraftOrder({ receiptAddress: selectedAddress?.id, address: address.id }, () => {
                this.setState({
                    selectedReceiveAddress: address,
                    isLoading: false,
                    openReceiveAddress: true,
                })
                if (cb) cb()
            })
        } else {
            updateLoadingStatus(false)
        }
    }

    onRemoveReceiveAddress = (address, cb) => {
        const { updateDraftOrder } = this.props
        const { selectedAddress } = this.state
        this.checkWareHouse(selectedAddress.id)
        updateDraftOrder({ receiptAddress: null, address: selectedAddress.id }, () => {
            this.setState({
                selectedReceiveAddress: null,
            })
            if (cb) cb()
        })
    }

    /**
     * ẩn hiện modal
     */
    showModal = () => {
        const { selectedAddress } = this.state
        if (selectedAddress) {
            this.setState({
                isReceivingAddress: false,
            })
            this.toggleModalAddress()
        } else {
            this.toggleModalCreateAddress()
        }
    }

    /**
     * render header của panel
     */
    renderPanelHeader = () => {
        return (
            <div className={'panel-address-header _deliveryAdressTitle'}>
                <h4>{this.props.t('customerAddress.delivery_address')}</h4>
            </div>
        )
    }

    render() {
        const { isUpdateDraftOrder, defaultAddress, className, t, showReceiveAddress } = this.props
        let {
            selectedAddress,
            modalCreateAddressStatus,
            modalAddressStatus,
            dataItem,
            checkWareHouse = {},
            selectedReceiveAddress,
            isReceivingAddress,
            openReceiveAddress,
        } = this.state
        return (
            <Spin
                spinning={isUpdateDraftOrder}
                indicator={
                    <AntdIcon.Loading3QuartersOutlined
                        type="loading"
                        style={{ fontSize: 24 }}
                        spin
                    />
                }
            >
                <React.Fragment>
                    <Collapse
                        accordion
                        defaultActiveKey={['1']}
                        className={`sidebar deliveryaddress position-re ${className || ''}`}
                    >
                        <Panel
                            header={this.renderPanelHeader()}
                            key={1}
                        >
                            <div>
                                {selectedAddress ? (
                                    <Fragment>
                                        <div className={'deliveryaddress__box pd20'}>
                                            <Row>
                                                <Col
                                                    md={24}
                                                    className={
                                                        '_address-fullname deliveryaddress__name position-re txt-size-h7 robotobold txt-color-black mgbt15 break-word'
                                                    }
                                                >
                                                    {selectedAddress.fullname}{' '}
                                                    <span className="normal">
                                                        {selectedAddress.addressName && `(${selectedAddress.addressName})`}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row className={'mgbt5'}>
                                                <Col
                                                    md={3}
                                                    className={'txt-size-h7 txt-color-gray'}
                                                >
                                                    <AntdIcon.HomeOutlined type="home" />
                                                </Col>
                                                <Col
                                                    md={21}
                                                    className={
                                                        '_address-detail txt-color-black txt-size-h7 robotoregular wordbreakall'
                                                    }
                                                >
                                                    {selectedAddress.detail}
                                                </Col>
                                            </Row>

                                            <Row className={'mgbt5'}>
                                                <Col
                                                    md={3}
                                                    className={'txt-size-h7 txt-color-gray'}
                                                >
                                                    <AntdIcon.PhoneOutlined type="phone" />
                                                </Col>
                                                <Col
                                                    md={21}
                                                    className={'_address-phone txt-color-black txt-size-h7 robotoregular'}
                                                >
                                                    {selectedAddress.phone}
                                                </Col>
                                            </Row>

                                            <Row className={'mgbt5'}>
                                                <Col
                                                    md={3}
                                                    className={'txt-size-h7 txt-color-gray'}
                                                >
                                                    <AntdIcon.GlobalOutlined type="global" />
                                                </Col>
                                                <Col
                                                    md={21}
                                                    className={
                                                        '_address-location-display txt-color-black txt-size-h7 robotoregular'
                                                    }
                                                >
                                                    {selectedAddress.location.display}
                                                </Col>
                                            </Row>
                                            {selectedAddress.note && (
                                                <Row className={'mgbt5'}>
                                                    <Col
                                                        md={3}
                                                        className={'txt-size-h7 txt-color-gray'}
                                                    >
                                                        <AntdIcon.MessageOutlined type="message" />
                                                    </Col>
                                                    <Col
                                                        md={21}
                                                        className={
                                                            '_address-note txt-color-black txt-size-h7 robotoregular break-word'
                                                        }
                                                    >
                                                        {selectedAddress.note}
                                                    </Col>
                                                </Row>
                                            )}
                                            <span
                                                className={
                                                    '_btn-select-address deliveryaddress__edit position-ab txt-size-h8 txt-color-blue cursor-pointer'
                                                }
                                                onClick={this.showModal}
                                            >
                                                {t('customerAddress.btn_edit')}
                                            </span>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Empty
                                        image={require('../../resources/images/empty-image.png')}
                                        description={<span>{t('customerAddress.no_address')}</span>}
                                    >
                                        <Button
                                            className="_btnCreateAddress"
                                            type="primary"
                                            onClick={() => this.showModal()}
                                        >
                                            {t('customerAddress.create_address')}
                                        </Button>
                                    </Empty>
                                )}
                            </div>
                            {selectedAddress && showReceiveAddress && (
                                <>
                                    <Divider className="my-2" />

                                    <Fragment>
                                        <Row
                                            justify="space-between"
                                            align="middle"
                                            className="mb-3"
                                        >
                                            <Col className="font-medium">
                                                <Space
                                                    size={12}
                                                    align="center"
                                                >
                                                    <span>
                                                        {t('customerAddress.receivingAddress')}
                                                        <Tooltip
                                                            placement="top"
                                                            title={t('customerAddress.tooltip')}
                                                        >
                                                            <i className="fa-regular fa-circle-info text-xs ml-1 text-grey-300" />
                                                        </Tooltip>
                                                    </span>
                                                    <Switch
                                                        checked={openReceiveAddress}
                                                        onChange={checked => {
                                                            if (checked) {
                                                                this.setState({
                                                                    isReceivingAddress: true,
                                                                })
                                                                this.toggleModalAddress()
                                                            } else {
                                                                Modal.confirm({
                                                                    maskClosable: true,
                                                                    icon: null,
                                                                    title: t('customerAddress.removeReceivingAddressConfirm'),
                                                                    okText: t('button.agree'),
                                                                    cancelText: t('button.disagree'),
                                                                    onOk: () => {
                                                                        this.onRemoveReceiveAddress()
                                                                        this.setState({
                                                                            openReceiveAddress: false,
                                                                        })
                                                                    },
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </Space>
                                            </Col>
                                            <Col>
                                                {selectedReceiveAddress && (
                                                    <Space size={12}>
                                                        <span
                                                            className="txt-size-h8 txt-color-blue cursor-pointer"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isReceivingAddress: true,
                                                                })
                                                                this.toggleModalAddress()
                                                            }}
                                                        >
                                                            {t('customerAddress.edit')}
                                                        </span>
                                                    </Space>
                                                )}
                                            </Col>
                                        </Row>
                                        {selectedReceiveAddress && (
                                            <div className={'deliveryaddress__box pd20'}>
                                                <Row>
                                                    <Col
                                                        md={24}
                                                        className={
                                                            '_address-fullname deliveryaddress__name position-re txt-size-h7 robotobold txt-color-black mgbt15 break-word'
                                                        }
                                                    >
                                                        {selectedReceiveAddress.fullname}{' '}
                                                        <span className="normal">
                                                            {selectedReceiveAddress.addressName &&
                                                                `(${selectedReceiveAddress.addressName})`}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className={'mgbt5'}>
                                                    <Col
                                                        md={3}
                                                        className={'txt-size-h7 txt-color-gray'}
                                                    >
                                                        <AntdIcon.HomeOutlined type="home" />
                                                    </Col>
                                                    <Col
                                                        md={21}
                                                        className={
                                                            '_address-detail txt-color-black txt-size-h7 robotoregular wordbreakall'
                                                        }
                                                    >
                                                        {selectedReceiveAddress.detail}
                                                    </Col>
                                                </Row>

                                                <Row className={'mgbt5'}>
                                                    <Col
                                                        md={3}
                                                        className={'txt-size-h7 txt-color-gray'}
                                                    >
                                                        <AntdIcon.PhoneOutlined type="phone" />
                                                    </Col>
                                                    <Col
                                                        md={21}
                                                        className={'_address-phone txt-color-black txt-size-h7 robotoregular'}
                                                    >
                                                        {selectedReceiveAddress.phone}
                                                    </Col>
                                                </Row>

                                                <Row className={'mgbt5'}>
                                                    <Col
                                                        md={3}
                                                        className={'txt-size-h7 txt-color-gray'}
                                                    >
                                                        <AntdIcon.GlobalOutlined type="global" />
                                                    </Col>
                                                    <Col
                                                        md={21}
                                                        className={
                                                            '_address-location-display txt-color-black txt-size-h7 robotoregular'
                                                        }
                                                    >
                                                        {selectedReceiveAddress.location.display}
                                                    </Col>
                                                </Row>
                                                {selectedReceiveAddress.note && (
                                                    <Row className={'mgbt5'}>
                                                        <Col
                                                            md={3}
                                                            className={'txt-size-h7 txt-color-gray'}
                                                        >
                                                            <AntdIcon.MessageOutlined type="message" />
                                                        </Col>
                                                        <Col
                                                            md={21}
                                                            className={
                                                                '_address-note txt-color-black txt-size-h7 robotoregular break-word'
                                                            }
                                                        >
                                                            {selectedReceiveAddress.note}
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        )}
                                    </Fragment>
                                </>
                            )}
                        </Panel>
                    </Collapse>

                    {selectedAddress && !checkWareHouse.inWarehouseArea ? (
                        <Alert
                            className="mgbt10 mgt10"
                            message={<span className="bold txt-size-h6">{t('order.notification')}</span>}
                            description={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('message.warning_warehouse', { warehouse: checkWareHouse.warehouse }),
                                    }}
                                />
                            }
                            type="warning"
                            showIcon
                            closable
                        />
                    ) : null}

                    <ListAddressModal
                        t={t}
                        defaultAddress={defaultAddress}
                        isUpdateDraftOrder={isUpdateDraftOrder}
                        selectedAddress={selectedAddress}
                        changeAddressSelected={this.changeAddressSelected}
                        toggleListAddressModal={this.toggleModalAddress}
                        modalStatus={modalAddressStatus}
                        toggleCreateModal={this.toggleModalCreateAddress}
                        modalCreateStatus={modalCreateAddressStatus}
                        modalCreateAddressStatus={this.state.modalCreateAddressStatus}
                        dataItem={dataItem}
                        isReceivingAddress={isReceivingAddress}
                        selectedReceiveAddress={selectedReceiveAddress}
                        changeReceiveAddressSelected={this.changeReceiveAddressSelected}
                    />
                </React.Fragment>
            </Spin>
        )
    }
}

export default withNamespaces('translation')(DeliveryAddress)
